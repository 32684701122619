/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/04/2021.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './image-header.scss'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { sendToDataLayer } from '../../utils/DataLayer'
import { PROJECT_REF } from '../../constant'

const ImageHeaderStrides = ({ openDrawer, readMoreRef, signupRef }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  //
  // we are using two different formhooks for mobile and desktop
  //

  const handleScrollToElement = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    sendToDataLayer({ event: 'READ_MORE_HEADER', projectRef: PROJECT_REF })
    readMoreRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const gotoSignup = () => {
    if (isMobile) {
      openDrawer()
    } else {
      signupRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const data = `lorem <b onmouseover="alert('mouseover');">ipsum</b>`

  return (
    <div className="d-flex position-relative image-header">
      <div className="container-fluid position-absolute h-100 " style={{ zIndex: -10 }}>
        <div className="row h-100" style={{ background: '#03090c' }}>
          <div className="col-12 h-100 px-0 mx-auto " style={{ background: 'white' }}>
            <StaticImage
              className="h-100 w-100"
              objectFit=""
              src="../../images/front-bubble.png"
              objectPosition="50% 0%"
              alt="studies and me"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
        </div>
      </div>

      <div className="container-fluid content-container h-100">
        <div className="row h-100" style={{}}>
          <div className="col-12 col-md-8 col-lg-6 h-100 d-flex align-items-center">
            <div className="px-3 ps-md-5 header-text">
              {/*<div dangerouslySetInnerHTML={{ __html: t('header.headline') }} />*/}
              <p className="text-center text-md-start mb-2" style={{ fontSize: '1.2rem', fontWeight: 700 }}>
                {t('header.headline')}
              </p>
              {/*<h6 className="text-center text-md-start mb-2 ">{t('header.headline')}</h6>*/}
              {/* Make a difference for people living with osteoarthritis */}
              <h1
                className="text-center text-md-start mt-4 mb-5 text-small"
                dangerouslySetInnerHTML={{ __html: t('header.subheadline') }}
              ></h1>
              {/*<h1 className="text-center text-md-start mt-4 mb-5 text-small">{t('header.subheadline')}</h1>*/}
              {/* Contribute to new knowledge about future treatment of osteoarthritis by participating in a clinical study */}
              <p className="text-center text-md-start">{t('header.body')}</p>
              <div className="mt-5 d-flex flex-md-row flex-column align-content-center">
                <button
                  onClick={openDrawer}
                  className="d-block d-md-none btn btn-primary  px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('form.signupButton')} {/* IS MOBILE*/}
                </button>

                <button
                  onClick={gotoSignup}
                  className="d-none d-md-block btn btn-primary  px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('form.signupButton')} {/* IS DESKTop*/}
                </button>

                <button
                  onClick={handleScrollToElement}
                  className="btn bg-white btn-shadow px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('header.readMoreButton')}
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-6 d-none d-md-flex flex-column align-items-end justify-content-center py-5">
            {/*<div className="bg-white  d-flex flex-column align-items-start">dadasda sdd</div>*/}

            <StaticImage
              className="h-100 w-100 "
              objectFit="contain"
              src="../../images/header-round.png"
              objectPosition="50% 50%"
              alt="studies and me"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageHeaderStrides
