/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import React from 'react'

//import CheckMark from '../assets/check-mark.svg'
import CheckMark from '../assets/check-mark.svg'

import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useMediaQuery } from 'react-responsive'

export const WhatsInItForYou = ({ signupRef, openDrawer }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const gotoSignup = () => {
    if (isMobile) {
      openDrawer()
    } else {
      signupRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="container">
      {/*Mobile header image*/}
      <div className="row" style={{}}>
        <div className="col-12 align-items-centerd-block d-md-none mb-7" style={{ marginTop: '100px' }}>
          <div className="d-flex justify-content-center">
            <StaticImage
              placeholder={'none'}
              quality={100}
              loading="lazy"
              src="../images/header-mobile.png"
              alt="studies and me"
            ></StaticImage>
          </div>
        </div>
      </div>

      <div className="row mt-md-3 mt-7 mt-md-0" style={{}}>
        <div className="col-12 my-0 my-md-2">
          {/* Whats In It For You */}
          <h4 className="mb-5 mt-0 mt-md-4 text-center text-md-start">{t('whatsInItForYou.title')}</h4>
        </div>
      </div>

      <div className="row" style={{}}>
        <p className="col-12 my-0 my-md-2">{t('whatsInItForYou.body')}</p>
      </div>

      <div className="row mt-5" style={{}}>
        <div className="col-12 col-md-4 my-0 my-md-2 d-flex justify-content-start">
          <div className="pe-lg-5">
            <BulletItem txtKeyHeader="whatsInItForYou.subheadline1" txtKeyBody="whatsInItForYou.text1"></BulletItem>
          </div>
        </div>
        <div className="col-12 col-md-4 my-0 my-md-2 d-flex justify-content-center">
          <div className="px-lg-4">
            <BulletItem txtKeyHeader="whatsInItForYou.subheadline2" txtKeyBody="whatsInItForYou.text2"></BulletItem>
          </div>
        </div>
        <div className="col-12 col-md-4 my-0 my-md-2 d-flex justify-content-end">
          <div className="ps-lg-5">
            <BulletItem txtKeyHeader="whatsInItForYou.subheadline3" txtKeyBody="whatsInItForYou.text3"></BulletItem>
          </div>
        </div>
      </div>

      <div className="row pt-5 py-md-5" style={{}}>
        <div className="col">
          <div className="d-flex w-100 w-lg-auto justify-content-center align-items-center mb-6">
            <button
              onClick={openDrawer}
              className="d-block d-md-none btn btn-primary  px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
            >
              {t('whatsInItForYou.signupButton')} {/* IS MOBILE*/}
            </button>
            <button
              onClick={gotoSignup}
              className="d-none d-md-block btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
            >
              {t('whatsInItForYou.signupButton')} {/* IS DESKTop*/}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const BulletItem = ({ txtKeyHeader, txtKeyBody }) => {
  const { t } = useTranslation()
  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-2">
          <CheckMark style={{ marginTop: 4, width: '2rem', height: '2rem' }}></CheckMark>
        </div>
        <div className="col-10 ps-2 ps-md-2 ps-xl-0">
          <p className="p-large-bold mt-2 m-0">{t(txtKeyHeader)}</p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <p className="mt-2" style={{ fontSize: '1rem' }}>
            {t(txtKeyBody)}
          </p>
        </div>
      </div>
    </div>
  )
}
